import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import API from '../../api'
import {
  Button,
  Spinner,
  Badge,
  InputGroup,
  Input,
  ButtonGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  Row,
  Col,
  InputGroupText
} from 'reactstrap';
import { Link } from 'react-router-dom';
import constants from '../../lib/constants';
import { _hasAccess } from '../../middleware/accessMiddleware'
import ConfirmModal from '../../components/notificationModal/ConfirmModal';
import { DownloadExcel } from 'src/lib/xlsx';
import moment from 'moment';
import ForumDetail from './detailForum';

class index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      menuCode: constants.MENU_LIST_FORUM,
      scope: [],
      columns: [
        {
          name: 'ID',
          selector: 'id',
          sortable: true,
          maxWidth: '5%'
        },
        {
          name: 'Fullname',
          selector: 'fullname',
          sortable: true,
        },
        {
          name: 'Email',
          selector: 'email',
          sortable: true
        },
        {
          name: 'Phone',
          cell: data =>
            <label>
              {data.users_created?.phone}
            </label>
        },
        {
          name: 'QR Code ID',
          selector: 'qrcode_id_conv',
          sortable: true,
        },
        {
          name: 'Product Name',
          selector: 'product_name',
          sortable: true,
          cell: data =>
            <>
              {data.is_fake_product ? <a title='Product Palsu' className='text-danger'>{data.product_name}</a> : data.product_name}
            </>
        },
        {
          name: 'Store Name',
          selector: 'store_name',
          sortable: true,
        },
        {
          name: 'Created At',
          sortable: true,
          cell: data => {
            return new Date(data.created_at).toLocaleString('sv-SE') + ' WIB'
          }
        },
        {
          name: 'Rewards',
          sortable: true,
          cell: data =>
            <label>
              {data.reward_status === 1 ? <div className='text-success'>Approved</div> : null}
              {data.reward_status === 2 ? <div className='text-danger'>Reject</div> : null}
              {data.reward_status === 0 ? '-' : null}
            </label>
        },
        {
          name: 'Status',
          sortable: true,
          cell: data =>
            <label>
              {data.is_banned === 1 ?
                <Badge
                  color="danger"
                  pill
                >
                  Banned
                </Badge>
                : null
              }
              {data.status === 0 ?
                <Badge
                  color="secondary"
                  pill
                >
                  Deleted
                </Badge>
                : null
              }
              {data.status === 1 && data.is_banned === 0 ?
                <Badge
                  color="success"
                  pill
                >
                  Showed
                </Badge>
                : null
              }
            </label>
        },
        {
          name: 'Action',
          selector: 'action',
          sortable: false,
          cell: data =>
            <>
              <div>
                <button type='button' className="text-dark btn btn-sm btn-outline-link" onClick={() => this.showDetail(data.id)} ><li className="fa fa-eye"></li></button>
                {
                  this.state.scope.includes('delete') ?
                    data.status === 1 ?
                      < button className="text-danger btn btn-sm btn-outline-link" onClick={() => this.showConfirmDelete(data.id)}><li className="fa fa-trash"></li></button>

                      : null
                    : null
                }
              </div>
            </>
        }
      ],
      loaderPage: true,
      loadingExport: false,
      data: [],
      editData: {},
      id: null,
      showTable: false,
      showModal: false,
      showModalDetail: false,
      detailDataLoading: false,
      detailData: {},
      search: '',
      fake: false,
      wrong_report: false,
      alert: {
        show: false,
        status: 'success',
        message: ''
      },
      paginationPerPage: 10,
      paginationTotalRows: 0,
      paginationPage: 1
    };
  }

  componentDidMount() {
    this.checkAccess();
    this.getList();
    this.setState({
      loaderPage: false
    })
  }

  async checkAccess() {
    const access = await _hasAccess(this.state.menuCode, 'read');
    console.log(access)
    if (access) {
      let scope = [];
      access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

      this.setState({
        scope: scope
      })
      return;
    }

    this.props.history.push(`/unauthorized`);
  }

  getList() {
    const search = this.state.search;
    console.log(search);
    API.get('forum?size='
      + this.state.paginationPerPage
      + '&page=' + this.state.paginationPage
      + '&search=' + this.state.search
      + '&fake=' + this.state.fake
      + '&wrong_report=' + this.state.wrong_report
      + (this.state.startDate ? '&start_date=' + this.state.startDate : '')
      + (this.state.endDate ? '&end_date=' + this.state.endDate : '')
      + '&banned=true'
      + '&is_backoffice=true'
      , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const result = res.data;
        this.setState({
          data: result.data.item,
          paginationTotalRows: result.data.totalItem,
          showTable: true
        })
        console.log(result)
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  showConfirmDelete = (id) => {
    this.setState({
      showModal: !this.state.showModal,
      id: id,
    });
  }

  changePage(page) {
    this.setState({
      paginationPage: page
    }, () => {
      this.getList();
    })
  }

  changeRowsPerPage(rowsPerPage) {
    this.setState({
      paginationPerPage: rowsPerPage
    }, () => {
      this.getList();
    })
  }

  onChange = (e) => {
    this.setState({
      search: e.target.value
    }, () => {
      this.getList()
    })
  }

  fakeProduct() {
    this.setState({
      fake: !this.state.fake
    }, () => {
      this.getList()
    })
  }

  wrongReport() {
    this.setState({
      wrong_report: !this.state.wrong_report
    }, () => {
      this.getList()
    })
  }

  downloadExcel() {
    this.setState({
      loadingExport: true
    })
    API.get('forum?size='
      + this.state.paginationPerPage
      + '&page=all'
      + '&search=' + this.state.search
      + '&fake=' + this.state.fake
      + '&wrong_report=' + this.state.wrong_report
      + '&is_backoffice=true'
      , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const newDatalist = res.data.data.item.map((data) => {
          return {
            Email: data.email,
            Phone: data.users_created?.phone,
            "Shieldcode ID": data.qrcode_id_conv,
            "Product Name": data.product_name,
            "Store Name": data.store_name,
            "Created At": `${moment(data.created_at).format("DD-MM-YYYY HH:mm")}`,
            "Status": data.is_banned === 1 ? 'Banned' : (data.status === 0 ? 'Deleted' : 'Showed'),
          };
        });
        DownloadExcel(newDatalist, `Export Forum_${moment().format("YYYYDDMMHHmm")} `);
        this.setState({
          loadingExport: false
        })
      })
      .catch((err) => {

      });
  };

  showDetail(forumId) {
    this.setState({
      detailDataLoading: true,
      showModalDetail: true
    })
    API.get('forum/' + forumId, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const result = res.data;
        this.setState({
          detailData: result.data,
          detailDataLoading: false
        })

      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  render() {
    return (
      !this.state.showTable ? <Spinner style={{ marginTop: '30%', marginLeft: '50%' }}>Loading</Spinner> :
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">

              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <strong className="card-title">Forum</strong>
                    <div className="float-right">
                      <Button onClick={() => this.downloadExcel()} size='sm' className='ml-2 mb-2 float-right' color={'primary'}>
                        {this.state.loadingExport ? <Spinner size='sm' color='light' /> : 'Export'}
                      </Button>
                      <ButtonGroup>
                        <UncontrolledDropdown size='sm'>
                          <DropdownToggle caret>
                            More
                          </DropdownToggle>
                          <DropdownMenu>
                            <Link to="/forum/place-to-buy" className='text-decoration-none'>
                              <DropdownItem >
                                Place to Buy
                              </DropdownItem>
                            </Link>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </ButtonGroup>
                    </div>
                  </div>
                  <div className="card-body">
                    <Form className='mb-4'>
                      <Row className="row-cols-lg-auto g-3 align-items-center">
                        <Col>
                          <Input
                            type='text'
                            placeholder='Search by Customer Name/Email/Product Name/Store Name/QR Code ID36'
                            onChange={this.onChange}
                          />
                        </Col>
                        <Col>
                          <InputGroup>
                            <InputGroupText>
                              Start Date
                            </InputGroupText>
                            <Input
                              type="date"
                              name='deactivedDate'
                              onChange={(e) => {
                                this.setState({
                                  startDate: e.target.value
                                }, () => {
                                  this.getList()
                                })
                              }}
                            />
                          </InputGroup>
                        </Col>
                        <Col>
                          <InputGroup>
                            <InputGroupText>
                              End Date
                            </InputGroupText>
                            <Input
                              type="date"
                              name='deactivedDate'
                              onChange={(e) => {
                                this.setState({
                                  endDate: e.target.value
                                }, () => {
                                  this.getList()
                                })
                              }}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    </Form>
                    {
                      this.state.fake ? <Button onClick={() => this.fakeProduct()} size='sm' className='mb-2 ml-2' color="success">Laporan Produk Palsu</Button>
                        : <Button onClick={() => this.fakeProduct()} size='sm' outline className='mb-2 ml-2'>Laporan Produk Palsu</Button>
                    }
                    <Button onClick={() => this.wrongReport()} size='sm' className='ml-2 mb-2' outline={this.state.wrong_report ? false : true} color={this.state.wrong_report ? 'success' : 'secondary'}> Laporan Terindikasi Salah</Button>
                    {
                      // !this.state.showTable ? <>Loading ...</> :
                      <DataTable
                        noHeader={true}
                        columns={this.state.columns}
                        data={this.state.data}
                        pagination={true}
                        paginationServer={true}
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                        paginationTotalRows={this.state.paginationTotalRows}
                        onChangePage={page => this.changePage(page)}
                        onChangeRowsPerPage={rowsPerPage => this.changeRowsPerPage(rowsPerPage)}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ConfirmModal
            open={this.state.showModal}
            close={() => this.showConfirmDelete()}
            afterSubmit={() => this.getList()}
            id={this.state.id}
            url={`forum/${this.state.id}`}
          />
          <ForumDetail
            open={this.state.showModalDetail}
            close={() => this.setState({ showModalDetail: false })}
            data={this.state.detailData}
            dataLoading={this.state.detailDataLoading}
            refresh={() => this.getList()}
          />
        </div>
    )
  }
}

export default index;