import React, { Component } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";



export default class NotifModalSweet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {},
      buttonLoading: this.props.loading ?? false,
      notif: false,
      status: false,
    }
  }

  componentDidUpdate() {
    if (this.props.show) {
      const MySwal = withReactContent(Swal)
      MySwal.fire({
        title: this.props.title ?? 'Berhasil',
        text: this.props.subtitle,
        icon: this.props.status ? 'success' : 'error',
        iconHtml: this.props.status ? '<i class="fa fa-check"></i>' : '<i class="fa fa-exclamation-triangle"></i>',
        iconColor: this.props.status ? '#4BB543' : '#FF0000',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn-confirm-swal',
          cancelButton: 'btn-cancel-swal',
          title: 'text-dark text-xl2',
          popup: 'my-popup-class'
        }
      })
        .then(async () => {
          MySwal.close();
          this.props.onClose();
        })
    }
  }

  render() {
    return (<></>);
  }
}