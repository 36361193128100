import React, { Component } from "react";
import {
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Form,
  Alert,
  Nav,
  NavItem,
  Badge,
  Input,
} from 'reactstrap';
import API from "../../api";
import ReportedForumCarousel from "./partials/ReportedForumCarousel";
import { NavLink } from "react-bootstrap";
import moment from "moment";
import PreviewImage from "src/components/elements/image/PreviewImage";
import NotifModalSweet from "src/components/notificationModal/notifModalSweet";

const required = ['reward_status']
export default class ForumDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {},
      disabled: true,
      buttonLoading: false,
      activeTab: 'detail',
      optionStatus: [
        { id: 1, name: 'Approved' },
        { id: 2, name: 'Rejected' },
      ],
      showImage: false,
      selectedImage: '',
      body: {
        reward_status: props.data.reward_status
      },
      notifModal: false,
      notifText: {
        title: 'Success',
        subtitle: 'Reward status has been updated successfully',
        status: true
      },
    }
  }

  switchTab(tab) {
    this.setState({
      activeTab: tab
    })
  }

  onSubmit() {
    this.setState({
      buttonLoading: true
    })

    API.put(`/forum/reward/${this.props.data.id}`, this.state.body, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        this.setState({
          buttonLoading: false,
          body: {
            reward_status: ''
          },
          notifModal: true,
          notifText: {
            title: res.data.status ? 'Success' : 'Failed',
            subtitle: res.data.status ? 'Reward status has been updated successfully' : 'Failed to update reward status',
            status: res.data.status
          }
        })
        this.props.close();
      })
      .catch(err => {
        this.setState({
          buttonLoading: false,
          body: {
            reward_status: ''
          },
          notifModal: true,
          notifText: {
            title: 'Failed',
            subtitle: 'Failed to update reward status',
            status: false
          }
        })
        this.props.close();
      })
  }

  onChange = (e) => {
    const error = this.state.error;
    const name = e.target.name;
    const isRequired = required.includes(name)
    if (!e.target.value && isRequired) {
      error[e.target.name] = 'Field is Required'
    }
    else {
      delete error[e.target.name]
    }

    const newBody = this.props.data
    newBody[e.target.name] = e.target.value
    this.setState({
      body: newBody,
      error: error
    }, () => {
      this.onValidation()
    });
  }

  onValidation() {
    const asArray = Object.entries(this.state.body);
    const filtered = asArray.filter(([key, value]) => value === '' && required.includes(key));
    this.setState({
      disabled: filtered.length ? true : false,
    });
  }

  showImage(image) {
    this.setState({
      selectedImage: image
    }, () => {
      this.setState({
        showImage: true
      })
    })
  }

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} toggle={() => this.props.close()} size="md">
          <ModalHeader toggle={() => this.props.close()}>Detail Reported Forum</ModalHeader>
          <ModalBody>
            <Nav className="mb-4" tabs>
              <NavItem>
                <NavLink
                  className={this.state.activeTab === 'detail' ? 'active font-weight-bold' : 'text-secondary'}
                  onClick={() => this.switchTab('detail')}
                >
                  Forum
                </NavLink>
              </NavItem>
              {
                this.props.data.user_reports?.length > 0 &&
                <NavItem>
                  <NavLink
                    className={this.state.activeTab === 'report' ? 'active font-weight-bold' : 'text-secondary'}
                    onClick={() => this.switchTab('report')}
                  >
                    User Report
                  </NavLink>
                </NavItem>
              }
              {
                this.props.data.comments?.length > 0 &&
                <NavItem>
                  <NavLink
                    className={this.state.activeTab === 'comment' ? 'active font-weight-bold' : 'text-secondary'}
                    onClick={() => this.switchTab('comment')}
                  >
                    Comments
                  </NavLink>
                </NavItem>
              }
            </Nav>
            {
              this.props.dataLoading ?
                <div className="">
                  <div class="skeleton skeleton-text" style={{ width: '25%' }}></div>
                  <div class="skeleton skeleton-body"></div>
                  <div class="skeleton skeleton-body" style={{ height: '2rem' }}></div>
                  <div class="skeleton skeleton-body" style={{ height: '2rem' }}></div>
                </div>
                :
                <>
                  <Form className={this.state.activeTab === 'detail' ? 'show' : 'd-none'}>
                    <div className="card-forum">
                      <div className="card-forum-header">
                        <div className="font-weight-bold">
                          {this.props.data.fullname}
                          {this.props.data.is_banned ? <Badge color="danger p-2 float-right" >Banned</Badge> : null}
                        </div>
                        <div className="text-secondary small">
                          {moment.utc(this.props.data.created_at).format('DD MMMM YYYY HH:mm:ss')} WIB
                        </div>
                      </div>
                      <div className="card-forum-body">
                        {this.props.data.images?.length > 0 ? <div className="mt-4"><ReportedForumCarousel images={this.props.data.images} /></div> : null}
                        <div className="content-forum mt-4">
                          <div className="title font-weight-bold">
                            {this.props.data.product_name}
                          </div>
                          {this.props.data.is_fake_product ? <div className="text-danger text-sm mb-2">Counterfeit Product Report</div> : null}
                          <div className="description">
                            {this.props.data.description}
                          </div>
                          <h6 className="mt-4 mb-2">Product Information</h6>
                          <div className="row bg-light p-2 m-0 rounded">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="small text-secondary">Brand</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.brand_name} </div>
                              </div>
                              <div className="form-group">
                                <label className="small text-secondary">Place to Buy</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.place_to_buy?.name} </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="small text-secondary">Purchase Date</label>
                                <div className="text-dark font-weight-bold"> {moment(this.props.data.purchase_date).format('DD MMMM YYYY')} </div>
                              </div>
                              <div className="form-group">
                                <label className="small text-secondary">Store Name</label>
                                <div className="text-dark font-weight-bold"> {this.props.data.is_sensor_store_name ? this.props.data.store_name.substring(0, 3) + '****' : this.props.data.store_name} </div>
                              </div>
                            </div>
                          </div>
                          <h6 className="mt-4 mb-2">Purchase Invoice</h6>
                          {
                            this.props.data.invoices?.length > 0 ? this.props.data.invoices?.map((item, index) => {
                              return <img onClick={() => this.showImage(this.props.data.invoices.map(item => item.image))} src={item.image} className="img-fluid mt-2" style={{ cursor: 'pointer' }} />
                            }) : <div className="text-secondary text-sm">No Invoice</div>
                          }
                          <h6 className="mt-4 mb-2">Reward Status</h6>
                          <div className="form-group">
                            <div className="text-dark font-weight-bold pr-3">
                              <Input
                                name="reward_status"
                                type="select"
                                onChange={this.onChange}
                                value={this.state.body.reward_status ?? this.props.data.reward_status}
                                invalid={this.state.error['reward_status'] ? true : false}
                              >
                                <option value={''}>Choose</option>
                                {
                                  this.state.optionStatus.map(item => {
                                    return <option value={item.id}>{item.name}</option>
                                  })
                                }
                              </Input>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                  <div className={this.state.activeTab === 'report' ? 'show' : 'd-none'}>
                    {
                      this.props.data.user_reports?.map((item, index) => {
                        return (
                          <div className={`row bg-light p-2 m-0 rounded mb-2`} >
                            <div className="font-weight-bold font-sm">
                              {item.customer.fullname}
                            </div>
                            <div className="text-secondary font-xs">
                              {moment.utc(item.created_at).format('DD MMMM YYYY HH:mm:ss')}
                            </div>
                            <div className="font-sm">
                              {item.description}
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                  <div className={this.state.activeTab === 'comment' ? 'show' : 'd-none'}>
                    {
                      this.props.data.comments?.map((item, index) => {
                        return (
                          <div className={`row bg-light p-2 m-0 rounded mb-2`} >
                            <div className="font-weight-bold font-sm">
                              {item.created_by?.fullname}
                            </div>
                            <div className="text-secondary font-xs">
                              {moment.utc(item.created_at).format('DD MMMM YYYY HH:mm:ss')}
                            </div>
                            <div className="font-sm">
                              {item.comment}
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </>
            }
          </ModalBody>
          <ModalFooter>
            <Button color="success" className="float-left" onClick={() => this.onSubmit()} disabled={this.state.disabled}>Submit</Button>
            <Button color="danger" onClick={() => this.props.close()}>Cancel</Button>
          </ModalFooter>
        </Modal>
        <PreviewImage show={this.state.showImage} image={this.state.selectedImage} onClose={() => this.setState({ showImage: false })} />
        <NotifModalSweet
          show={this.state.notifModal}
          title={this.state.notifText.title}
          subtitle={this.state.notifText.subtitle}
          status={this.state.notifText.status}
          onClose={() => {
            this.setState({ notifModal: false })
            this.props.refresh();
          }}
        />
      </>
    )
  }
}