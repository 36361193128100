import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import API from 'src/api'
import {
  Spinner,
  Badge,
  InputGroup,
  Input
} from 'reactstrap';
import constants from 'src/lib/constants';
import { _hasAccess } from 'src/middleware/accessMiddleware'
import ConfirmModal from 'src/components/notificationModal/ConfirmModal';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { addCommas, formatDate } from 'src/lib/helpers';
import ConfirmModalSweet from 'src/components/notificationModal/ConfrimModalSweet';

class index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      menuCode: constants.MENU_LEADS_TRACKING_DATA_LEADS,
      scope: [],
      data: [],
      showTable: false,
      showModalDelete: false,
      showModalDetail: false,
      search: '',
      searchStatus: null,
      alert: {
        show: false,
        status: 'success',
        message: ''
      },
      paginationPerPage: 10,
      paginationTotalRows: 0,
      paginationPage: 1,
      totalWaiting: 0,
      selectedDataLoading: true,
      selectedData: {},
      notifModal: false,
      notifText: {
        title: 'Are you sure ?',
        subtitle: 'Make sure you have checked the data',
      },
      status: [
        { value: '0', label: 'Deleted', color: 'secondary' },
        { value: '1', label: 'Cold', color: 'info' },
        { value: '2', label: 'Hot', color: 'warning' },
        { value: '3', label: 'Super Hot', color: 'primary' },
        { value: '4', label: 'Accepted', color: 'success' },
        { value: '5', label: 'Losses', color: 'danger' },
      ]
    };
  }

  componentDidMount() {
    this.checkAccess();
    this.getList();
  }

  async checkAccess() {
    const access = await _hasAccess(this.state.menuCode, 'read');
    if (access) {
      let scope = [];
      access.scope.map(item => item.status === 1 ? (scope.push(...[item.name])) : null)

      this.setState({
        scope: scope
      })
      return;
    }

    this.props.history.push(`/unauthorized`);
  }

  getList() {
    const search = this.state.search;
    API.get('leads?size='
      + this.state.paginationPerPage
      + '&page=' + this.state.paginationPage
      + '&search=' + this.state.search
      + (this.state.searchStatus ? '&status=' + this.state.searchStatus : '')
      , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const result = res.data;
        this.setState({
          data: result.data.item,
          paginationTotalRows: result.data.totalItem,
          showTable: true,
        })
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  changePage(page) {
    this.setState({
      paginationPage: page
    }, () => {
      this.getList();
    })
  }

  changeRowsPerPage(rowsPerPage) {
    this.setState({
      paginationPerPage: rowsPerPage
    }, () => {
      this.getList();
    })
  }

  onChange = (e) => {
    if (e.target.value.length > 3 || e.target.value === '') {
      this.setState({
        search: e.target.value
      }, () => {
        this.getList()
      })
    }
  }

  render() {
    return (
      !this.state.showTable ? <Spinner style={{ marginTop: '30%', marginLeft: '50%' }}>Loading</Spinner> :
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-md-12">
                <div className="card" >
                  <div className="card-header">
                    <strong className="card-title">Data Leads</strong>
                    <div className="float-right">
                      <Link to='/leads-tracking/data-leads/add'>
                        <button className='btn btn-sm btn-primary'>
                          <i className='fa fa-plus-square mr-1'></i> Add Leads
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className='d-flex'>
                      <InputGroup className='w-auto'>
                        <Input
                          placeholder='Search by Company Name'
                          onChange={this.onChange}
                        />
                      </InputGroup>
                    </div>
                    {
                      <DataTable
                        columns={[
                          {
                            name: 'ID',
                            selector: 'id',
                            maxWidth: '5%'
                          },
                          {
                            name: 'Date',
                            cell: data => formatDate(data.created_at) + ' WIB'
                          },
                          {
                            name: 'Company Name',
                            selector: 'company_name',
                          },
                          {
                            name: 'Brand Name',
                            selector: 'brand_name',
                          },
                          {
                            name: 'PIC Name',
                            selector: 'pic_name',
                          },
                          {
                            name: 'QTY',
                            cell: data => data.qty_total ? addCommas(data.qty_total) : 0,
                            maxWidth: '5%'
                          },
                          {
                            name: 'Price',
                            cell: data => data.price_total ? addCommas(data.price_total) : 0,
                            maxWidth: '5%'
                          },
                          {
                            name: 'Status',
                            maxWidth: '5%',
                            cell: data =>
                              <label>
                                <Badge
                                  color={this.state.status.filter(item => item.value == data.status)[0].color}
                                  pill
                                >
                                  {this.state.status.filter(item => item.value == data.status)[0].label}
                                </Badge>
                              </label>
                          },
                          {
                            name: 'Action',
                            selector: 'action',
                            cell: data =>
                              // (
                              //   <Dropdown alignRight className="custom-dropdown">
                              //     <Dropdown.Toggle bsPrefix="super-btn" variant="outline-link" id="dropdown-basic">
                              //       <i className='fa fa-ellipsis-v'></i>
                              //     </Dropdown.Toggle>

                              //     <Dropdown.Menu className='custom-dropdown-menu'>
                              //       <Link to={`/leads-tracking/data-leads/${data.id}`} className="text-decoration-none">
                              //         <Dropdown.Item href="#"><li className="fa fa-eye mr-2"></li>Detail</Dropdown.Item>
                              //       </Link>
                              //       <Link to={`/leads-tracking/data-leads/${data.id}`} className="text-decoration-none">
                              //         <Dropdown.Item href="#"><li className="fa fa-edit mr-2"></li>Edit</Dropdown.Item>
                              //       </Link>
                              //       <Link to={`/leads-tracking/data-leads/${data.id}`} className="text-decoration-none">
                              //         <Dropdown.Item href="#"><li className="fa fa-trash mr-2"></li>Delete</Dropdown.Item>
                              //       </Link>
                              //       <Link to={`/leads-tracking/data-leads/${data.id}`} className="text-decoration-none">
                              //         <Dropdown.Item href="#"><li className="fa fa-plus mr-2"></li>Add Product</Dropdown.Item>
                              //       </Link>
                              //     </Dropdown.Menu>
                              //   </Dropdown>
                              // )
                              <>
                                <div>
                                  <Link to={`/leads-tracking/data-leads/${data.id}`}>
                                    <button type='button' className="text-dark btn btn-sm btn-outline-link" data-toggle="tooltip" title="Detail"><li className="fa fa-eye"></li></button>
                                  </Link>
                                  {
                                    this.state.scope.includes('update') && data.status !== 0 ?
                                      <Link to={`/leads-tracking/data-leads/edit/${data.id}`}>
                                        <button className="text-primary btn btn-sm btn-outline-link" ata-toggle="tooltip" title="Edit"><li className="fa fa-edit"></li></button>
                                      </Link>
                                      : null
                                  }
                                  {
                                    this.state.scope.includes('delete') && data.status !== 0 ?
                                      <button className="text-danger btn btn-sm btn-outline-link" type='button' onClick={() => this.setState({
                                        showModalDelete: true,
                                        selectedData: data
                                      })} ata-toggle="tooltip" title="Delete"><li className="fa fa-trash"></li></button>
                                      : null
                                  }
                                  {
                                    this.state.scope.includes('update') && data.status !== 0 ?
                                      <Link to={`/leads-tracking/data-leads/product/${data.id}`}>
                                        <button className="text-success btn btn-sm btn-outline-link" ata-toggle="tooltip" title="Products"><li className="fa fa-cubes"></li></button>
                                      </Link>
                                      : null
                                  }
                                  {
                                    this.state.scope.includes('update') && data.status !== 0 ?
                                      <button className="text-info btn btn-sm btn-outline-link" onClick={() => {
                                        if (data.qty_total === 0) {
                                          this.setState({
                                            notifModal: true,
                                            notifText: {
                                              title: 'Warning',
                                              subtitle: 'Please add product first'
                                            },
                                            selectedData: data
                                          })
                                          return;
                                        }

                                        this.props.history.push(`/leads-tracking/offering-letter/add/${data.id}`)
                                      }} ata-toggle="tooltip" title="Create Offering Letter"><li className="fa fa-send-o"></li></button>
                                      : null
                                  }
                                </div>
                              </>
                          }
                        ]}
                        noHeader={true}
                        data={this.state.data}
                        pagination={true}
                        paginationServer={true}
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                        paginationTotalRows={this.state.paginationTotalRows}
                        onChangePage={page => this.changePage(page)}
                        onChangeRowsPerPage={rowsPerPage => this.changeRowsPerPage(rowsPerPage)}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ConfirmModal
            text={`Are you sure want to delete this leads ?`}
            open={this.state.showModalDelete}
            close={() => {
              this.setState({ showModalDelete: false, selectedData: {} })
              this.getList();
            }}
            afterSubmit={() => this.getList()}
            url={`leads/${this.state.selectedData.id}`}
          />
          <ConfirmModalSweet
            show={this.state.notifModal}
            title={this.state.notifText.title}
            confirmText='Add Product'
            onConfirm={() => { this.setState({ notifModal: false }); this.props.history.push(`/leads-tracking/data-leads/product/${this.state.selectedData.id}`); }}
            onCancel={() => this.setState({ notifModal: false })}
            subtitle={this.state.notifText.subtitle}
          />
        </div>
    )
  }
}

export default index;